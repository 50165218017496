<template>
  <div class="home">

    <b-modal id="modal-intro" hide-footer centered>
      <div class="text-center">
        <h2 class="mb-4" v-if="$store.state.isMobile">Swipe to browse experiences</h2>
        <h2 class="mb-4" v-else>Click and drag to browse experiences</h2>
        <img width="50" class="anim-swipe icon-swipe m-auto" src="/img/swipe.png" alt="Swipe">
        <div class="d-block mt-3">
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary btn-lg" v-on:click="hideModal()">Got it</button>
          </div>
        </div>
      </div>
    </b-modal>

    <div>
      <button class="hub-arrow hub-arrow-prev btn"><img src="/img/icon-arrow-right.svg" alt="Previous" v-on:click="moveSlider(-1)"></button>
      <button class="hub-arrow hub-arrow-next btn"><img src="/img/icon-arrow-right.svg" alt="Next" v-on:click="moveSlider(1)"></button>

      <div class="hub-slider gsapSlider">
        <flicking
          ref="hubSlider"
          :options="{ 
            gap: 300, 
            moveType: 'snap', 
            circular: true,
            duration: 400,
            autoResize: true
          }"
          :tag="'div'"
          :viewportTag="'div'"
          :cameraTag="'div'"
          @move-end="changeSlide()"
          @move="startMove()"
        >


          <div class="hub-slide">
            <div class="d-flex min-vh-100">
              <div class="offsetTop">
                <h1>Meet the President</h1>
                <p class="lead">A personal intro to Hult</p>
                <b-link v-on:click="goExternal('https://beem.plus/hult_meetthepresident/')" class="btn btn-primary btn-lg">Watch in AR</b-link>
              </div>
            </div>
          </div>

          <div class="hub-slide">
            <div class="d-flex min-vh-100">
              <div class="offsetTop">
                <h1>Media Room</h1>
                <p class="lead">Spend some time with us</p>
                <b-link to="/media" class="btn btn-primary btn-lg">Watch & Listen</b-link>
              </div>
            </div>
          </div>

          <div class="hub-slide">
            <div class="d-flex min-vh-100">
              <div class="offsetTop">
                <h1>Campus Quiz</h1>
                <p class="lead">Find your perfect match</p>
                <b-link to="/quiz" class="btn btn-primary btn-lg">Take the Quiz</b-link>
              </div>
            </div>
          </div>

          <div class="hub-slide">
            <div class="d-flex min-vh-100">
              <div class="offsetTop">
                <h1>Faces of Hult</h1>
                <p class="lead">Meet our community cover stars</p>
                <b-link to="/faces" class="btn btn-primary btn-lg">Get Acquainted</b-link>
              </div>
            </div>
          </div>

          <div class="hub-slide">
            <div class="d-flex min-vh-100">
              <div class="offsetTop">
                <h1>Future Problems</h1>
                <p class="lead">Try a tech-led business challenge</p>
                <b-link v-on:click="goExternal('https://hultdigital.8thwall.app/disruptive-tech/')" class="btn btn-primary btn-lg">Let's Do This</b-link>
              </div>
            </div>
          </div>

        </flicking>

      </div>
  
    </div>
    
  </div>
</template>

<script>
// import { Fade, AutoPlay } from "@egjs/flicking-plugins";
import { gsap } from "gsap";

export default {
  name: 'Home',
  // components: {Fade, Autoplay},
  data() {
    return {
      showIntro: false
    }
  },
  computed: {
  },
  methods: {
    moveSlider: function (direction) {
      console.log(direction)
      if (direction === -1) {
        console.log('prev');
        this.$refs.hubSlider.prev();
      } else {
        console.log('next');
        this.$refs.hubSlider.next();
      }
    },
    changeSlide: function () {
      sessionStorage.setItem('skipSlideInfo', 1);
      var index = this.$refs.hubSlider.getIndex();
      console.log(index)
      this.$store.state.hubSlideNumber = index;
      this.adjustRotation(index);
    },
    adjustRotation: function(index) {
      if (index == 0) {
        this.$store.state.cameraMoveTo = 'exp0';
      } else if (index == 1) {
        this.$store.state.cameraMoveTo = 'exp1';
      } else if (index == 2) {
        this.$store.state.cameraMoveTo = 'exp2';
      } else if (index == 3) {
        this.$store.state.cameraMoveTo = 'exp3';
      } else if (index == 4) {
        this.$store.state.cameraMoveTo = 'exp4';
      }
    },
    startMove: function () {
      this.$store.state.hubSlideNumber = -1;
      this.$store.state.cameraMoveTo = 'zoomOut';
    },
    hideIntro: function () {
      var self = this;
      self.$store.state.cameraMoveTo = "browseInit";
      this.$store.state.homeIntroPresented = true;
      gsap.to('.gsapIntro', {autoAlpha: 0, y: -10, duration: 1, onComplete: function () {
        self.showIntro = false;
        gsap.to('.gsapSlider', {autoAlpha: 1, duration: 1});
        gsap.to('.hub-arrow', {autoAlpha: 1, duration: 0.2, delay:0.5});
        if (sessionStorage.getItem('skipSlideInfo') != 1) {
          setTimeout(function(){ 
            self.openModal();
          }, 1000);
        }

      }});
    },
    goExternal: function(url) {
      this.$store.state.cameraMoveTo = "external";
      setTimeout(function(){ 
        window.location.href = url
      }, 1000);
    },
    openModal: function () {
      this.$bvModal.show('modal-intro');
    },
    hideModal: function () {
      this.$bvModal.hide('modal-intro');
    },
  },
  mounted() {
    var self = this;
    this.$store.state.logoHide = false;
    this.$store.state.home = true;
    this.$store.state.bodyBackground = "bg-none";
    this.$store.state.cameraMoveTo = "init";
    console.log('here', this.$store.state.previousPage.name)

    if ((this.$store.state.previousPage.name != null && this.$store.state.previousPage.name !== 'Home' && this.$store.state.homeIntroPresented)) {
      console.log('Came from another page', this.$store.state.previousPage);
      this.$store.state.cameraMoveTo = "browseInit";
      this.showIntro = false;
      self.$store.state.hubSlideNumber = self.$store.state.previousPage.meta.homeSlide;
      self.$refs.hubSlider.moveTo(self.$store.state.previousPage.meta.homeSlide);
    } else {
      this.$store.state.hubSlideNumber = -1;
      this.showIntro = true;
      this.$store.state.cameraMoveTo = "init";

      gsap.set('.gsapSlider', {autoAlpha: 0});
      gsap.set('.hub-arrow', {autoAlpha: 0});

      setTimeout(function(){ 
        self.hideIntro();
      }, 100);
    }

  }
}
</script>
